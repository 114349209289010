import React, { useState, useContext, useEffect } from 'react';
import { Typography, Card, CardContent, CardMedia, Grid, Box, IconButton, Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Context as BlogContext } from "../../context/BlogContext";
import { Context as GalleryContext } from "../../context/GalleryContext";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const GalleryScreen = () => {
    const navigate = useNavigate();
    const [galleryListData, setGalleryListData] = useState([])
    const { state: { BlogList, BlogListStatus, DeleteBlogStatus }, getAllBlogs, clearGetAllBlogsStatus, deleteBlog, clearDeleteBlogStatus } = useContext(BlogContext);
    const { state: { GalleryList, DeleteGalleryStatus }, getAllGallery, clearGetAllGalleryStatus, deleteGallery, clearDeleteGalleryStatus, } = useContext(GalleryContext);

    useEffect(() => {
        getAllGallery()
    }, []);
    console.log("gallery", galleryListData)
    useEffect(() => {
        // if (BlogList) {
        //     const sortedBlog = BlogList?.data?.slice().sort((a: _id, b: _id) => b._id - a._id);
        //     setBlogListData(sortedBlog)
        // }

        // if (BlogList) {
        //     const sortedBlog = BlogList?.data?.slice().sort((a, b) =>
        //         new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        //     );
        //     setBlogListData(sortedBlog);
        // }
        if (GalleryList) {
            // const sortedBlog = BlogList?.data?.slice().sort((a, b) =>
            //     new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            // );
            setGalleryListData(GalleryList?.data);
        }
    }, [GalleryList])

    useEffect(() => {
        if (DeleteGalleryStatus !== "") {
            if (DeleteGalleryStatus === 200) {
                // setStatus(200)
                // setOpenAlert(true)
                clearDeleteGalleryStatus();
                getAllGallery();
            } else {
                // setStatus(400)
                // setOpenAlert(true)
            }
        }
    }, [DeleteGalleryStatus]);


    const handleDelete = (id) => {
        console.log("delete customer id >", id)
        deleteGallery(id);
        // setOpenDelete(false);
    };


    const handleEdit = (post) => {
        navigate('/blog/edit', { state: { post } });
    };

    return (
        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Gallery
                </Typography>
                <Button variant="contained" color="primary"
                    component={Link}
                    to="/gallery/create"
                >
                    Create
                </Button>
            </Box>
            {!GalleryList || GalleryList?.data?.length === 0 ?
                <Typography variant="h6">No gallery image available.</Typography> :
                <Grid container spacing={4}>
                    {galleryListData?.map((post) => (
                        <Grid item xs={12} key={post._id}>
                            <Card>
                                <Box display="flex">
                                    <Box display="flex" flex="1" alignItems="center">
                                        <CardMedia
                                            component="img"
                                            sx={{ width: 270, height: 170, objectFit: 'cover' }}
                                            image={post.mainImage}
                                            alt={post.title}
                                        />
                                        <Box ml={2}>
                                            <Typography variant="h6" component="div">
                                                {post.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box flexDirection="column" justifyContent="space-between">
                                        <Box display="flex" justifyContent="flex-end" padding="8px">
                                            <IconButton aria-label="edit" onClick={() => handleEdit(post)}>
                                                <EditIcon color="primary" />
                                            </IconButton>
                                            <IconButton aria-label="delete" onClick={() => handleDelete(post._id)}>
                                                <DeleteIcon color="primary" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            }
        </div >
    );
};

export default GalleryScreen;
