import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const GalleryReducer = (state, action) => {
    switch (action.type) {
        case "get_all_gallery":
            return { ...state, GalleryList: action.payload };
        case "get_all_gallery_status":
            return { ...state, GalleryListStatus: action.payload };
        case "clear_get_all_gallery_status":
            return { ...state, GalleryListStatus: "" };

        case "create_gallery":
            return { ...state, CreateGallery: action.payload };
        case "create_gallery_status":
            return { ...state, CreateGalleryStatus: action.payload };
        case "clear_create_gallery_status":
            return { ...state, CreateGalleryStatus: "" };

        case "delete_gallery_status":
            return { ...state, DeleteGalleryStatus: action.payload };
        case "clear_delete_gallery_status":
            return { ...state, DeleteGalleryStatus: "" };

        case "update_blog_status":
            return { ...state, UpdateBlogStatus: action.payload };
        case "clear_update_blog_status":
            return { ...state, UpdateBlogStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllGallery = (dispatch) => async () => {
    try {
        const response = await listAPI.get('gallery/getAllGallery');
        dispatch({ type: "get_all_gallery", payload: response.data });
        dispatch({ type: "get_all_gallery_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_gallery_status", payload: 400 });
    }
};

const clearGetAllGalleryStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_gallery_status" });
};


/** Create Gallery **/
const createGallery = dispatch => async ({ bannerImage, name, image }) => {
    try {
        const form = new FormData();

        form.append('name', name);
        // form.append('images', image);
        form.append('images', bannerImage);
        image.forEach((file) => {
            // console.log("Appending file:", file.name);
            form.append("images", file)
        })

        const response = await listAPI.post("gallery/add", form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "create_gallery", payload: response.data });
        dispatch({ type: "create_gallery_status", payload: response.status });
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "create_gallery_status", payload: 400 })

    }
}

const clearCreateGalleryStatus = dispatch => () => {
    dispatch({ type: "clear_create_gallery_status" });
};

/** Update Blog **/
const updateBlog = dispatch => async ({ blogId, topic, description, image }) => {
    try {
        const form = new FormData();

        form.append('topic', topic);
        form.append('description', description);
        form.append('images', image);

        const response = await listAPI.put("/blog/update/" + blogId, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        dispatch({ type: "update_blog", payload: response.data });
        dispatch({ type: "update_blog_status", payload: response.status });
        console.log("create_blog", response);
    } catch (err) {
        console.log(err.message);
        dispatch({ type: "update_blog_status", payload: 400 })
    }
}


const clearUpdateBlogStatus = dispatch => () => {
    dispatch({ type: "clear_update_blog_status" });
};


/** Delete gallery **/
const deleteGallery = (dispatch) => async (imgId) => {
    try {
        const response = await listAPI.delete("/gallery/deleteGalleryById/" + imgId);
        dispatch({ type: "delete_gallery_status", payload: response.status });
        console.log("test", response)
    } catch (e) {
        console.log(e);
        dispatch({ type: "delete_gallery_status", payload: 400 });
    }
};
const clearDeleteGalleryStatus = (dispatch) => () => {
    dispatch({ type: "clear_delete_gallery_status" });
};


export const { Provider, Context } = CreateDataContext(
    GalleryReducer,
    {
        getAllGallery, clearGetAllGalleryStatus,
        createGallery, clearCreateGalleryStatus,
        deleteGallery, clearDeleteGalleryStatus,
        updateBlog, clearUpdateBlogStatus
    },
    {
        GalleryList: [],
        GalleryListStatus: "",
        CreateGallery: [],
        CreateGalleryStatus: "",
        DeleteGalleryStatus: "",
        UpdateBlogStatus: ""
    }
);
