import React, { useState, useEffect, useContext } from 'react';
import { Typography, Card, CardContent, CardMedia, Grid, Box, IconButton, Button, Alert } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import { Context as ContactContext } from "../../context/ContactContext";


const ContactUsScreen = () => {
    const navigate = useNavigate();
    const [allData, setAllData] = useState([])
    const [open, setOpen] = useState(false);
    const { state: { ContactList, ContactListStatus, }, getAllContactMessage, clearGetAllContactStatus, } = useContext(ContactContext);


    useEffect(() => {
        getAllContactMessage()
    }, []);


    useEffect(() => {
        setAllData(ContactList?.data)
    }, [ContactList])


    const columns = [
        { field: '_id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 130 },
        { field: 'email', headerName: 'Email', width: 130 },
        { field: 'subject', headerName: 'Subject', width: 130 },
        { field: 'message', headerName: 'Message', width: 160 },
    ];


    const paginationModel = { page: 0, pageSize: 5 };

    return (

        <div>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4" gutterBottom>
                    Contact Us
                </Typography>
                {/* <Button variant="contained" color="primary"
                    component={Link}
                    to="/event/create"
                >
                    Create
                </Button> */}
            </Box>
            <Paper sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={allData}
                    columns={columns}
                    getRowId={(row) => row._id}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[5, 10]}
                    // checkboxSelection
                    sx={{ border: 0 }}
                />
            </Paper>


        </div >
    );
}

export default ContactUsScreen