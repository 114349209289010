import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, IconButton, Divider, Typography, TextField, TextareaAutosize, InputLabel, MenuItem, FormHelperText, FormControl, Select, Grid2, } from '@mui/material';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useNavigate } from 'react-router-dom';
import { Context as GalleryContext } from "../../context/GalleryContext";
import DeleteIcon from "@mui/icons-material/Delete";


const CreateGallery = () => {
    const navigate = useNavigate();
    const [topic, setTopic] = useState('')
    const [description, setDescrition] = useState('')
    const [image, setImage] = useState("");
    const [value, setValue] = useState(dayjs());
    const [timeValue, setTimeValue] = useState(null);
    const [timeValueFormatted, setTimeValueFormatted] = useState(null);
    const [selectType, setSelectType] = useState('');
    const [menuImage, setMenuImage] = useState("");
    const [fileNames, setFileNames] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, open } = state;

    const { state: { CreateGalleryStatus }, createGallery, clearCreateGalleryStatus, } = useContext(GalleryContext);


    const handleChange = (event) => {
        setSelectType(event.target.value);
    };


    const handleImageChange = (e) => {
        const imageFile = e.target.files[0];
        if (imageFile) {
            setImage(imageFile);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        try {
            createGallery({
                name: topic,
                image: fileNames,
                bannerImage: menuImage
            });

        } catch (error) {
            console.log("Error saving form data:", error);
        }
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const handleClick = (newState) => () => {
        setState({ ...newState, open: true });
    };


    useEffect(() => {
        if (CreateGalleryStatus === 201) {
            console.log("success")
            handleClick()
            clearCreateGalleryStatus();
            navigate('/gallery');
            // const timeoutId = setTimeout(() => {
            //     clearCreateBlogStatus();
            //     navigate('/blog');
            // }, 2000);
        } else if (CreateGalleryStatus === 400 || CreateGalleryStatus === 404) {
            clearCreateGalleryStatus();
        }
    }, [CreateGalleryStatus]);

    const ImageUploadField = ({ label, placeholder = "Select an image...", onChange }) => {

        const handleImageChange = (event) => {
            const file = event.target.files[0];
            if (file) {
                setMenuImage(file);
                if (onChange) onChange(file);
            }
        };

        const handleRemoveImage = () => {
            setMenuImage(null);
            if (onChange) onChange(null);
        };

        return (
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ color: '#000', mb: 1, fontSize: '14px' }}>{label}</Typography>
                <input
                    accept="image/*"
                    type="file"
                    style={{ display: 'none' }}
                    id="image-upload"
                    onChange={handleImageChange}
                />
                <label htmlFor="image-upload">
                    <TextField
                        fullWidth
                        variant="outlined"
                        // size="small"
                        placeholder={placeholder}
                        // value={menuImage.name}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    // size="small"
                                    component="span"
                                    sx={{ ml: 1, textTransform: "none", backgroundColor: 'blue' }}
                                >
                                    Select
                                </Button>
                            ),
                        }}
                    />
                </label>
                <Typography sx={{ color: '#000', mb: 0, fontSize: '12px' }}>(width and height must be 1500px*500px)</Typography>

                {menuImage && (
                    <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                        <Box position="relative">
                            <img
                                src={URL.createObjectURL(menuImage)}
                                alt="Preview"
                                style={{
                                    marginTop: 5,
                                    width: "100%",
                                    // maxWidth: 200,
                                    height: 100,
                                    // borderRadius: 8,
                                    // border: "1px solid #ccc",
                                }}
                            />
                            <IconButton
                                onClick={handleRemoveImage}
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    // backgroundColor: "rgba(255, 255, 255, 0.7)",
                                    // "&:hover": { backgroundColor: "rgba(255, 0, 0, 0.7)" },
                                }}
                            >
                                <DeleteIcon color="error" />
                            </IconButton>
                        </Box>
                    </Box>
                )}

            </Box>
        );
    };



    const MultipleImageUploadField = ({ label, placeholder = "Select images...", onChange }) => {

        const handleImageChange = (event) => {
            const files = Array.from(event.target.files);
            const newFileNames = files.map((file) => file);
            setFileNames((prev) => [...prev, ...newFileNames]);

            const newFilePreviews = files.map((file) => ({
                file,
                preview: URL.createObjectURL(file),
            }));

            setFilePreviews((prev) => [...prev, ...newFilePreviews]);

            if (onChange) onChange(files);
        };

        const handleRemoveImage = (index) => {
            setFileNames((prev) => prev.filter((_, i) => i !== index));
            setFilePreviews((prev) => {
                const updatedPreviews = prev.filter((_, i) => i !== index);
                return updatedPreviews;
            });
        };

        return (
            <Box sx={{ mb: 2 }}>
                <Typography sx={{ color: "#000", mb: 1, fontSize: '14px' }}>{label}</Typography>
                <input
                    accept="image/*"
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    id="multi-image-upload"
                    onChange={handleImageChange}
                // disabled={menuImage !== "" ? false : true}
                />
                <label htmlFor="multi-image-upload">
                    <TextField
                        fullWidth
                        //multiline
                        rows={fileNames.length || 1}
                        variant="outlined"
                        // size="small"
                        placeholder={placeholder}
                        InputProps={{
                            readOnly: true,
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    // size="small"
                                    component="span"
                                    sx={{ ml: 1, textTransform: "none", backgroundColor: 'blue', color: "#fff" }}
                                // disabled={menuImage !== "" ? false : true}
                                >
                                    Select
                                </Button>
                            ),
                        }}
                    />
                </label>
                <Typography sx={{ color: '#000', mb: 0, fontSize: '12px' }}>(width and height must be 450px*650px/Ideally)</Typography>

                {/* {fileNames.length > 0 && (
                    <Grid2 container spacing={2} sx={{ mt: 2 }}>
                        {fileNames.map((fileName, index) => (
                            <Grid2 item xs={6} key={index}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        backgroundColor: "#f9f9f9",
                                        // padding: "6px 8px",
                                        borderRadius: "4px",
                                        width: "100%",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: "0.85rem",
                                            color: "#555",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        {fileName.name}
                                    </Typography>
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() => handleRemoveImage(index)}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Grid2>
                        ))}
                    </Grid2>
                )} */}
                {filePreviews.length > 0 && (
                    <Grid2 container spacing={2} sx={{ mt: 2 }}>
                        {filePreviews.map((item, index) => (
                            <Grid2 item xs={6} key={index}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        backgroundColor: "#f9f9f9",
                                        padding: "8px",
                                        borderRadius: "4px",
                                        width: "100%",
                                        position: "relative",
                                    }}
                                >
                                    <img
                                        src={item.preview}
                                        alt="Preview"
                                        style={{
                                            width: "100%",
                                            height: "100px",
                                            objectFit: "cover",
                                            borderRadius: "4px",
                                        }}
                                    />
                                    <IconButton
                                        size="small"
                                        color="error"
                                        onClick={() => handleRemoveImage(index)}
                                        sx={{ position: "absolute", top: 5, right: 5, backgroundColor: "#fff" }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            </Grid2>
                        ))}
                    </Grid2>
                )}
            </Box>
        );
    };


    return (
        // <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box component="form"
            onSubmit={handleSubmit}
            sx={{ mx: 'auto', p: 2 }}>
            <Typography variant="h4" gutterBottom>
                Create Gallery
            </Typography>

            <Box>
                {/* <Typography variant="subtitle1" gutterBottom>
                        Instagram post link
                    </Typography>
                    <TextField
                        label="Instagram post link"
                        variant="outlined"
                        fullWidth
                        name="Instagram post link"
                        value={topic}
                        onChange={(event) => setTopic(event.target.value)}
                        // margin="normal"
                        required
                    /> */}
                <Typography variant="subtitle1" gutterBottom>
                    Banner Name
                </Typography>
                {/* <FormControl sx={{}} fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={selectType}
                        label="Type"
                        onChange={handleChange}
                    >
                      
                        <MenuItem value={'DogRuns'}>Dog Runs</MenuItem>
                        <MenuItem value={'DoggyPaddles'}>Doggy Paddles</MenuItem>
                        <MenuItem value={'Hikes'}>Hikes</MenuItem>
                        <MenuItem value={'DogCafes'}>Dog Cafes</MenuItem>
                    </Select>
                   
                </FormControl> */}
                <TextField
                    label="Banner Name"
                    variant="outlined"
                    fullWidth
                    name="Banner Name"
                    value={topic}
                    onChange={(event) => setTopic(event.target.value)}
                    // margin="normal"
                    required
                />

            </Box>

            <Box mt={2} mb={2}>
                <ImageUploadField label="Banner Image"
                //onChange={handleImageUpload} 
                />
            </Box>

            <Box mt={2} mb={2}>
                <MultipleImageUploadField label="Images" />
            </Box>

            <Box mt={3}>
                <Button
                    // onClick={() => handleSubmit()}
                    type="submit" variant="contained" color="primary" fullWidth>
                    Create
                </Button>
            </Box>
        </Box>
        // </LocalizationProvider>
    );
};

export default CreateGallery;
