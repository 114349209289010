import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const ContactReducer = (state, action) => {
    switch (action.type) {
        case "get_all_contact":
            return { ...state, ContactList: action.payload };
        case "get_all_contact_status":
            return { ...state, ContactListStatus: action.payload };
        case "clear_get_all_contact_status":
            return { ...state, ContactListStatus: "" };

        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllContactMessage = (dispatch) => async () => {
    try {
        const response = await listAPI.get('contact/getAll');
        dispatch({ type: "get_all_contact", payload: response.data });
        dispatch({ type: "get_all_contact_status", payload: response.status, });

    } catch (e) {
        console.log("errrr", e);
        dispatch({ type: "get_all_contact_status", payload: 400 });
    }
};

const clearGetAllContactStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_contact_status" });
};


export const { Provider, Context } = CreateDataContext(
    ContactReducer,
    {
        getAllContactMessage, clearGetAllContactStatus,
    },
    {
        ContactList: [],
        ContactListStatus: "",
    }
);
