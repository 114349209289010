import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import PrivateRoute from './PrivateRoute';
import Login from '../src/screens/Login'
import Dashboard from './screens/Dashborad';
import BlogScreen from './screens/Blog/BlogScreen';
import { Provider as BlogProvider } from "./context/BlogContext";
import { Provider as EventProvider } from "./context/EventContext";
import { Provider as BookingProvider } from "./context/BookingContext";
import { Provider as InstaProvider } from "./context/InstaContext";
import { Provider as GalleryProvider } from "./context/GalleryContext";
import { Provider as ContactProvider } from "./context/ContactContext";
import CreateBlogScreen from './screens/Blog/CreateBlog';
import EditBlog from './screens/Blog/EditBlog';
import EventScreen from './screens/Event/EventScreen';
import CreateEvent from './screens/Event/CreateEvent';
import EditEvent from './screens/Event/EditEvent';
import RegisteredUsersScreen from './screens/Booking/RegisteredUsersScreen';
import CreateInsta from './screens/insta/CreateInsta';
import InstagramScreen from './screens/insta/InstagramScreen';
import GalleryScreen from './screens/Gallery/GalleryScreen';
import CreateGallery from './screens/Gallery/CreateGallery';
import ContactUsScreen from './screens/ContactUs/ContactUsScreen';

const theme = createTheme();

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <>
      <ThemeProvider theme={theme}>
        <InstaProvider>
          <BlogProvider>
            <EventProvider>
              <BookingProvider>
                <GalleryProvider>
                  <ContactProvider>
                    <Router>
                      <Routes>
                        <Route path="/" element={<Login />} />

                        <Route element={<Dashboard />}>
                          <Route path="/blog" element={<BlogScreen />} />
                          <Route path="/blog/create" element={<CreateBlogScreen />} />
                          <Route path="/blog/edit" element={<EditBlog />} />
                          <Route path="/event" element={<EventScreen />} />
                          <Route path="/event/create" element={<CreateEvent />} />
                          <Route path="/event/edit" element={<EditEvent />} />
                          <Route path="/event/registered-users" element={<RegisteredUsersScreen />} />
                          <Route path="/instagram-post" element={<InstagramScreen />} />
                          <Route path="/instagram-post/create" element={<CreateInsta />} />
                          <Route path="/gallery" element={<GalleryScreen />} />
                          <Route path="/gallery/create" element={<CreateGallery />} />
                          <Route path="/contact-us" element={<ContactUsScreen />} />
                        </Route>

                      </Routes>
                    </Router>
                  </ContactProvider>
                </GalleryProvider>
              </BookingProvider>
            </EventProvider>
          </BlogProvider>
        </InstaProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
